<div *ngIf="(dDCCService.vehicleConfigLoading$ | async) || (dDCCService.campaignLoading$ | async)">
    <dd-spinner>
    </dd-spinner>
</div>
<div class="close-dialog-icon-container d-flex">
    <mat-icon class="close-dialog-icon" mat-dialog-close>close</mat-icon>
</div>
<div class="common-campaign-container">
    <div class="modify-rate-rule-container">
        <div class="header">
            <div mat-dialog-title>
                {{('LOCALIZATION.CAMPAIGNS.CAMPAIGN' | translate) + ': ' + data.campaign.title}}
            </div>
            <div mat-dialog-title>
                {{('LOCALIZATION.CAMPAIGNS.' + data.action + '_RATE_RULE') | translate}}
            </div>
        </div>
    </div>
    <div [formGroup]="rateRuleForm" class="modify-rate-rule-container container-fluid p-3">
        <div class="d-flex">
            <div class="col-md-4">
                <mat-label class="d-flex mb-1 fw-bold">
                    {{'LOCALIZATION.CAMPAIGNS.OFFER_TYPE' | translate}}
                </mat-label>
                <mat-select [ngClass]="{'input-error': rateRuleForm.controls.offerType.invalid }"
                    formControlName="offerType" class="rate-rule-input">
                    <mat-option
                        *ngFor="let offerType of (data.campaign.lenderId | getlenderoffertypes : ( lenderList$ | async))"
                        [value]="offerType">
                        {{offerType | titlecase}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-md-4">
                <mat-label class="d-flex mb-1 fw-bold">
                    {{'LOCALIZATION.CAMPAIGNS.SALES_CLASS' | translate}}
                </mat-label>
                <mat-select [ngClass]="{'input-error': rateRuleForm.controls.salesClass.invalid }"
                    formControlName="salesClass" class="rate-rule-input" multiple>
                    <mat-option [value]="salesClass"
                        *ngFor="let salesClass of data.campaign | getsalesclassesforcampaign">
                        {{salesClass | titlecase}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="col-md-4">
                <mat-label class="d-flex mb-1 fw-bold">
                    {{'LOCALIZATION.CAMPAIGNS.MODEL_CODE' | translate}}
                </mat-label>
                <mat-select [ngClass]="{'input-error': rateRuleForm.controls.modelCode.invalid }"
                    formControlName="modelCode" class="rate-rule-input" multiple selectAll>
                    <mat-option [value]="allOptionValue">{{'LOCALIZATION.COMMON.ALL' | translate}}</mat-option>
                    <mat-option *ngFor="let model of data.campaign.make | filtermodelcodes
                    : data.campaign.model : data.campaign.year : (vehicleConfig$ | async)" [value]="model.code">
                        {{model.code + ' - ' + model.name}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <div class="d-flex">
            <div class="col-md-12">
                <span class="addLabel addRow">
                    <span class="material-icons" (click)="addTerm()">add</span>
                    <span (click)="addTerm()">{{'LOCALIZATION.CAMPAIGNS.ADD_TERM' | translate}}</span>
                </span>
            </div>
        </div>
        <div *ngIf="termList.length > 0" class="pos-relative">
            <div class="title-span">
                <span class="fw-bold">{{'LOCALIZATION.CAMPAIGNS.RATE_TABLE' | translate}}</span>
                <span *ngIf="rateRuleForm.controls.salesClass.value | checkmultipleaprclassesselected"
                    class="note-span">
                    {{'LOCALIZATION.CAMPAIGNS.LOWEST_STATE_APR_NOTE' | translate}}
                </span>
            </div>
            <div class="d-flex flex-column rate-table-container">
                <table class="table w-100" aria-describedby="rate-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th scope="col" class="minw-100px">
                                {{'LOCALIZATION.CAMPAIGNS.MIN_TERM' | translate}}
                            </th>
                            <th scope="col" class="minw-100px">
                                {{'LOCALIZATION.CAMPAIGNS.MAX_TERM' | translate}}<em
                                    class="max-term-info tmi tmi-info ml-1 p-0"
                                    matTooltip="{{'LOCALIZATION.CAMPAIGNS.MAX_TERM_TOOLTIP' | translate}}"></em>
                            </th>
                            <th scope="col">
                                {{'LOCALIZATION.CAMPAIGNS.MARKUP' | translate}}
                            </th>
                            <th scope="col" [attr.colspan]="dDCCService.creditTiers?.length + 2">
                                {{ dDCCService.isSETDealer ? '' :
                                'LOCALIZATION.CAMPAIGNS.BASE_RATE' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody formArrayName="termRate">
                        <tr *ngFor="let termRate of termRateForm.controls, index as termIndex, last as lastRow"
                            [formGroupName]="termIndex" [ngClass]="{'border-bottom': !lastRow}">
                            <td>
                                <mat-icon style="position: relative;top: 7px;" class="pointer" class="highlight_off"
                                    (click)="removeTerm(termIndex)">
                                    close</mat-icon>
                            </td>
                            <td formGroupName="term">
                                <input formControlName="min"
                                    [ngClass]="{'input-error': termRate.controls.term.controls.min.invalid }"
                                    [matTooltip]="getTooltip(termRate.get('term.min'))" matTooltipShowDelay="500" title
                                    class="rate-rule-input no-number-arrow" step="any" type="number">
                            </td>
                            <td formGroupName="term">
                                <input formControlName="max"
                                    [ngClass]="{'input-error': termRate.controls.term.controls.max.invalid }"
                                    [matTooltip]="getTooltip(termRate.get('term.max'))" matTooltipShowDelay="500" title
                                    class="rate-rule-input no-number-arrow" step="any" type="number">
                            </td>
                            <td>
                                <input [ngClass]="{'input-error': termRate.controls.markup.invalid }"
                                    formControlName="markup" [matTooltip]="termRate.controls.markup.invalid ?
                                        ('LOCALIZATION.COMMON.RANGE_VALIDATION_ERROR' | translate : 
                                        ({'min': getMarkupConfig().min,'max': getMarkupConfig().max})) : ''"
                                    matTooltipShowDelay="500" title class="rate-rule-input no-number-arrow" step="any"
                                    type="number" />
                            </td>
                            <td [attr.colspan]="dDCCService.creditTiers?.length + 2">
                                <div class="base-rate-table px-2 pt-2 pb-0">
                                    <table aria-describedby="term-adv-percentage" class="w-100">
                                        <thead>
                                            <tr *ngIf="dDCCService.isSETDealer">
                                                <th colspan="2" scope="col">
                                                    {{'LOCALIZATION.CAMPAIGNS.ADVANCE_PERCENTAGE' | translate}}</th>
                                                <th scope="col" [attr.colspan]="dDCCService.creditTiers?.length + 2">
                                                    {{'LOCALIZATION.CAMPAIGNS.BASE_RATE' | translate}}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="col" *ngIf="dDCCService.isSETDealer">
                                                    {{'LOCALIZATION.CAMPAIGNS.MIN' | translate}}(%)
                                                </th>
                                                <th scope="col" *ngIf="dDCCService.isSETDealer">
                                                    {{'LOCALIZATION.CAMPAIGNS.MAX' | translate}}(%)
                                                </th>
                                                <th scope="col" class="fs-13px px-1 vertical-align-middle"
                                                    *ngFor="let creditTier of dDCCService.creditTiers">
                                                    {{creditTier.score}}<br />{{"(" + creditTier.range + ")"}}
                                                </th>
                                                <th scope="col" class="pl-1" *ngIf="dDCCService.isSETDealer">
                                                    {{'LOCALIZATION.CAMPAIGNS.DEFAULT' | translate}}
                                                </th>
                                                <th scope="col" class="px-3" *ngIf="dDCCService.isSETDealer">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody formArrayName="advancePercentage">
                                            <tr *ngFor="let advPercentage of termRate.controls.advancePercentage.controls, index as advIndex, last as lastRow"
                                                [formGroupName]="advIndex" [ngClass]="{'border-bottom': !lastRow}">
                                                <td *ngIf="dDCCService.isSETDealer">
                                                    <input formControlName="min" class="rate-rule-input no-number-arrow"
                                                        [ngClass]="{'input-error': advPercentage.controls.min.invalid || advPercentage.errors?.InvalidMinMax ||
                                                        advPercentage.errors?.OverlappingMinMax }" [matTooltip]="advPercentage.errors?.OverlappingMinMax ?
                                                        ('LOCALIZATION.CAMPAIGNS.ADV_RANGE_OVERLAPPING_ERROR' | translate) :
                                                        advPercentage.controls.min.invalid ?
                                                        ('LOCALIZATION.COMMON.RANGE_VALIDATION_ERROR' | translate : 
                                                        ('advancePercentage' | getrangeforproperty)) : ''"
                                                        matTooltipShowDelay="500" title type="number" step="any" />
                                                </td>
                                                <td *ngIf="dDCCService.isSETDealer">
                                                    <input formControlName="max" class="rate-rule-input no-number-arrow"
                                                        [ngClass]="{'input-error': advPercentage.controls.max.invalid || advPercentage.errors?.InvalidMinMax ||
                                                        advPercentage.errors?.OverlappingMinMax }" [matTooltip]="advPercentage.errors?.OverlappingMinMax ?
                                                        ('LOCALIZATION.CAMPAIGNS.ADV_RANGE_OVERLAPPING_ERROR' | translate) :
                                                        advPercentage.controls.max.invalid ?
                                                        ('LOCALIZATION.COMMON.RANGE_VALIDATION_ERROR' | translate : 
                                                        ('advancePercentage' | getrangeforproperty)) : ''"
                                                        matTooltipShowDelay="500" title type="number" step="any" />
                                                </td>
                                                <ng-container *ngIf="('baseRate' | getrangeforproperty : rateRuleForm.controls.offerType.value :
                                                    rateRuleForm.controls.salesClass.value) as baseRateMinMax"
                                                    formArrayName="rates">
                                                    <td *ngFor="let rate of advPercentage.controls.rates.controls, index as rateIndex"
                                                        [formGroupName]="rateIndex">
                                                        <input class="rate-rule-input no-number-arrow"
                                                            [ngClass]="{'input-error': advPercentage.controls.rates.errors?.atleastNRequired || rate.controls.baseRate.invalid }"
                                                            formControlName="baseRate" type="number" step="any"
                                                            [matTooltip]="rate.controls.baseRate.invalid ?
                                                            ('LOCALIZATION.COMMON.' + (rateRuleForm.controls.offerType.value == offerTypes.FINANCE ?
                                                            'RANGE_VALIDATION_MAX_STATE_APR_ERROR' : 'RANGE_VALIDATION_ERROR') | translate : 
                                                            baseRateMinMax) : advPercentage.controls.rates.errors?.atleastNRequired ? ('LOCALIZATION.CAMPAIGNS.ONE_RATE_REQ' | translate) : ''"
                                                            matTooltipShowDelay="500" title />
                                                    </td>
                                                </ng-container>
                                                <td *ngIf="dDCCService.isSETDealer">
                                                    <mat-slide-toggle disableRipple="true" formControlName="isDefault">
                                                    </mat-slide-toggle>
                                                </td>
                                                <td *ngIf="dDCCService.isSETDealer">
                                                    <mat-icon class="pt-1"
                                                        *ngIf="termRate.controls.advancePercentage.controls.length > 1"
                                                        (click)="deleteAdvPencentage(advPercentage, advIndex, termRate.controls.advancePercentage)">
                                                        close
                                                    </mat-icon>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex mb-2">
                                        <span *ngIf="rateRuleForm.controls.offerType.value == offerTypes.FINANCE &&
                                         termRate.controls.showWarning.value" class="d-flex my-auto note-span">
                                            {{'LOCALIZATION.CAMPAIGNS.MARKUP_ADJUSTMENT_WARNING' | translate :
                                            ('baseRate' | getrangeforproperty : rateRuleForm.controls.offerType.value :
                                            rateRuleForm.controls.salesClass.value) }}
                                        </span>
                                        <button *ngIf="dDCCService.isSETDealer"
                                            class="d-flex secondary-button clear-button small-button ml-auto"
                                            (click)="addAdvPencentage(termRate.controls.advancePercentage)">
                                            <span class="material-icons">add</span>
                                            <span>{{'LOCALIZATION.COMMON.ADD' | translate}}</span>
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div *ngIf="termList.length > 0 && residualsForm.controls.length > 0" class="pos-relative">
            <div class="title-span">
                <span class="fw-bold">
                    {{'LOCALIZATION.CAMPAIGNS.RESIDUAL_TABLE' | translate}}
                    ({{'LOCALIZATION.CAMPAIGNS.IN_PER' | translate}})
                </span>
                <span class="note-span">
                    {{'LOCALIZATION.CAMPAIGNS.DEFAULT_MILEAGE_NOTE' | translate : ({"mileage": defaultMileage$ |
                    async})}}
                </span>
            </div>
            <div class="d-flex flex-column residual-table-container">
                <div>
                    <table class="table w-100" aria-describedby="rate-table">
                        <thead>
                            <tr>
                                <th scope="col" class="minw-100px">{{'LOCALIZATION.CAMPAIGNS.MODEL_CODE' |
                                    translate}}
                                </th>
                                <th scope="col" class="minw-100px">{{'LOCALIZATION.CAMPAIGNS.MODEL_YEAR' |
                                    translate}}
                                </th>
                                <th scope="col" class="minw-100px">{{'LOCALIZATION.CAMPAIGNS.SALES_CLASS' |
                                    translate}}
                                </th>
                                <ng-container *ngFor="let variance of defaultVariances$ | async">
                                    <th scope="col" *ngIf="variance.mileage !== (defaultMileage$ | async)">
                                        {{(variance.mileage | shortNumber) + ' ' +
                                        (variance.mileage > (defaultMileage$ | async) ? 'HMR' : 'LMB')}}
                                    </th>
                                </ng-container>
                                <th scope="col" class="fs-14px" *ngFor="let term of termList">
                                    {{term}}
                                </th>
                            </tr>
                        </thead>
                        <tbody [formGroup]="residualsForm">
                            <tr *ngFor="let residual of residualsForm.controls, index as residualIndex, last as lastRow"
                                [ngClass]="{'border-bottom': !lastRow}" [formGroupName]="residualIndex">
                                <td>{{residual.controls.modelCode.value}}</td>
                                <td>{{residual.controls.modelYear.value}}</td>
                                <td>{{residual.controls.salesClass.value | titlecase}}</td>
                                <ng-container formArrayName="variances">
                                    <ng-container
                                        *ngFor="let variance of residual.controls.variances.controls, index as varianceIndex">
                                        <td [formGroupName]="varianceIndex"
                                            *ngIf="variance.controls.mileage.value !== (defaultMileage$ | async)">
                                            <input formControlName="value" class="rate-rule-input no-number-arrow"
                                                [ngClass]="{'input-error': variance.controls.value.invalid }"
                                                [matTooltip]="variance.controls.value.invalid ?
                                                    ('LOCALIZATION.COMMON.RANGE_VALIDATION_ERROR' | translate : 
                                                    (variance.controls.mileage.value >= (defaultMileage$ | async) ?
                                                    dDCCService.hmrVariance : dDCCService.lmbVariance )) : ''"
                                                matTooltipShowDelay="500" title type="number" step="any" />
                                        </td>
                                    </ng-container>
                                </ng-container>
                                <ng-container formArrayName="terms">
                                    <td [formGroupName]="termResidualIndex"
                                        *ngFor="let term of residual.controls.terms.controls, index as termResidualIndex">
                                        <input formControlName="value" class="rate-rule-input no-number-arrow"
                                            [ngClass]="{'input-error': term.controls.value.invalid }" type="number"
                                            [matTooltip]="term.controls.value.invalid ?
                                            ('LOCALIZATION.COMMON.RANGE_VALIDATION_ERROR' | translate : 
                                            ('residual' | getrangeforproperty)) : ''" matTooltipShowDelay="500" title
                                            step="any" />
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex mt-2 mr-3">
    <button class="secondary-button ml-auto mr-3" #matDialogCloseBtn mat-dialog-close>
        {{'LOCALIZATION.COMMON.CANCEL' | translate}}
    </button>
    <button type="button" class="primary-button" (click)="saveRateRule()"
        [disabled]="termList.length === 0 || rateRuleForm.invalid || residualsForm.invalid">
        {{'LOCALIZATION.COMMON.' + data.action | translate}}
    </button>
</div>