import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserProfileService } from '@toyota/dd365-platform-library';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as uuid from 'uuid';
import { brandList } from '../../../mocks/brands';
import { Brands } from '../../shared/enums/brands.enum';
import { GenericSelections } from '../../shared/enums/generic-selections.enum';
import { SalesClasses } from '../../shared/enums/sales-classes.enum';
import { IBrand } from '../../shared/models/brand.model';
import { IVehicleBrand } from '../../shared/models/vehicle-config-master.model';
import { DealerInfoService } from '../../shared/services/dealer-info.service';
import { SubscriptionList, unsubscribeSubscriptions } from '../../shared/services/util.service';
import { selectBrands } from '../../store/incentives/incentives.selectors';
import * as vehicleConfigActions from '../../store/vehicle-config/vehicle-config.actions';
import { VehicleConfigState } from '../../store/vehicle-config/vehicle-config.reducer';
import { selectVehicleBrands } from '../../store/vehicle-config/vehicle-config.selectors';
import { IIncentive } from '../../shared/models/incentives.model';
import { IncentivesState } from '../../store/incentives/incentives.reducer';
import { IncentiveDisclosure } from '../../shared/enums/incentive-disclosure.enum';
import { IncentiveStatus } from '../../shared/enums/incentive-status.enum';

@Injectable({
  providedIn: 'root',
})
export class IncentivesComponentService {

  currentDate: DateTime;
  rtiBrands: string[] = [Brands.TOYOTA, Brands.LEXUS];
  salesClasses: string[] = [];
  yearsToAllowNewSalesClass = [];
  incentiveList: IIncentive[] = [];
  vehicleConfig: IVehicleBrand[] = [];
  allValue = GenericSelections.ALL;
  incentiveLoading$: Observable<boolean>;
  vehicleConfigLoading$: Observable<boolean>;
  subs: SubscriptionList = {};
  vehicleBrands: IBrand[] = [];

  constructor(
    private readonly userProfileService: UserProfileService,
    private readonly dealerInfoService: DealerInfoService,
    private readonly IncentiveState: Store<IncentivesState>
  ) {
    const currentDateTime = DateTime.local();
    this.currentDate = DateTime.utc(
      currentDateTime.year,
      currentDateTime.month,
      currentDateTime.day
    );
    this.yearsToAllowNewSalesClass = [
      'All',
      currentDateTime.year.toString(),
      (currentDateTime.year + 1).toString(),
      (currentDateTime.year - 1).toString(),
    ];
    this.subs.brandListData = this.IncentiveState.select(selectBrands).subscribe(data => {
      this.vehicleBrands = data;
    });
  }

  get isSETDealer() {
    return this.dealerInfoService.isSETDealer();
  }

  buildDraftIncentive(): IIncentive {
    return {
      id: uuid.v4(),
      dealerCode: this.userProfileService.getProfile().dealerCd,
      description: '',
      disclosure: '',
      title: '',
      make: brandList[0].id,
      makeName: '',
      year: [this.allValue],
      yearNames: [],
      model: [],
      modelNames: [],
      startDate: null,
      endDate: null,
      status: IncentiveStatus.DRAFT,
      incentiveAmount: null,
      salesClass: [],
      salesClassNames: [],
      offerType: [],
      offerTypeNames: [],
      incentiveSource: '',
      dealerFunded: false
    }
  }

  checkAndfetchVehicleConfig(brand: string, vehicleConfigState: Store<VehicleConfigState>) {
    vehicleConfigState.select(selectVehicleBrands).pipe(take(1)).subscribe(brands => {
      if (_.indexOf(brands, brand) < 0) {
        if (this.rtiBrands.includes(brand)) {
          vehicleConfigState.dispatch(new vehicleConfigActions.LoadRTIVehicleConfig({ brand }));
        }
        else {
          vehicleConfigState.dispatch(new vehicleConfigActions.LoadNHTSAVehicleConfig({ brand }));
        }
      }
    });
  }

  currentAndFutureDateCheck = (d: DateTime): boolean => this.currentDate <= d;

  checkInvalidStartAndEndDate(startDate: DateTime, endDate: DateTime) {
    return startDate && endDate && endDate.diff(startDate, ['days']).days < 0
  }

  checkincentiveDateOverlap(c1: IIncentive, c2: IIncentive) {
    const c1Start = this.getLuxonUTCDate(c1.startDate);
    const c1End = this.getLuxonUTCDate(c1.endDate);
    const c2Start = this.getLuxonUTCDate(c2.startDate);
    const c2End = this.getLuxonUTCDate(c2.endDate);
    return c1Start && c1End && c2Start && c2End &&
      c2End.diff(c1Start, ['days']).days >= 0 &&
      c1End.diff(c2Start, ['days']).days >= 0;
  }

  getLuxonUTCDate(date: string) {
    return date ? DateTime.fromISO(date, { zone: 'UTC' }) : null;
  }

  isNewSalesClassAllowed(incentiveMake: string, incentiveYear: string[]) {
    const currentYear = DateTime.local().year;
    const yearsToAllowNew = [
      GenericSelections.ALL,
      currentYear.toString(),
      (currentYear + 1).toString(),
      (currentYear - 1).toString(),
    ];
    return this.rtiBrands.includes(incentiveMake) &&
      _.intersection(incentiveYear, yearsToAllowNew).length > 0;
  }

  getSalesClassForIncentive(incentiveMake: string, incentiveYear: string[]) {
    if (!this.rtiBrands.includes(incentiveMake)) {
      return [SalesClasses.USED];
    }
    return this.isNewSalesClassAllowed(incentiveMake, incentiveYear) ?
      this.salesClasses : this.salesClasses.filter(salesClass => salesClass !== SalesClasses.NEW);
  }

  findConflictingIncentive(incentive: IIncentive) {
    return _.filter(this.incentiveList, (eIncentive) =>
      incentive.id !== eIncentive.id &&
      eIncentive.status === IncentiveStatus.ACTIVE &&
      incentive.incentiveSource === eIncentive.incentiveSource &&
      incentive.make === eIncentive.make &&
      (incentive.model.includes(this.allValue) ||
        eIncentive.model.includes(this.allValue) ||
        _.intersection(incentive.model, eIncentive.model).length > 0) &&
      (incentive.year.includes(this.allValue) ||
        eIncentive.year.includes(this.allValue) ||
        _.intersection(incentive.year, eIncentive.year).length > 0) &&
      this.checkincentiveDateOverlap(incentive, eIncentive)
    ).map(t => t.title);
  }

  getBrandById(id) {
    return _.find(this.vehicleBrands, ['id', id]) || id;
  }

  getModelByCode(data, code) {
    return _.find(data, ['code', code]) || code;
  }

  /* istanbul ignore next */
  destroySubs() {
    unsubscribeSubscriptions(this.subs);
  }
}