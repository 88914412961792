import { UserProfileService } from "@toyota/dd365-platform-library";
import {
  IDeploymentList
} from "../../shared/models/deploy-job-management.model";
import { IGetAuditLogsResponse } from "../../shared/models/audit-log.model";
import { IDJAuditLogs, IDJAuditLog, IGetDJAuditLogsResponse } from "../../shared/models/audit-log.model";

export function loadInitiateState() {
  const response: IDeploymentList = {
    deployments: []
  };
  return response;
}

export function loadInitiateStateConfig() {
  const response: IGetAuditLogsResponse = {
    trails:
    {
      items: [],
      totalItems: 0
    }
  };
  return response;
}

export function loadInitiateAuditLogStateConfig() {
  const response: IGetDJAuditLogsResponse = {
    trails:
    {
      items: [],
      totalItems: 0
    }
  };
  return response;
}