<nav [class.pointer-events-none]="disableNavigation" *ngIf="router.url !== '/pe-admin/config-not-found'">
  <a [routerLink]="'/pe-admin/offer-markup'" [class.selected]="pathSelected === 'offer-markup'"
    (click)="onClick('offer-markup');" matTooltip="{{'LOCALIZATION.COMMON.DEPLOYMENT_PENDING' | translate}}"
    [matTooltipDisabled]="!(('Offer Markup' | checkpendingJobs:'OfferMarkup') | async)">
    {{ 'LOCALIZATION.MENU_ITEMS.OFFER_MARKUP' | translate}}
    <span class="deployment-pending-dot" *ngIf="(('Offer Markup' | checkpendingJobs:'OfferMarkup')) | async"></span>
  </a>
  <a [routerLink]="'/pe-admin/lease-setting'" [class.selected]="pathSelected === 'lease-setting'"
    (click)="onClick('lease-setting');" matTooltip="{{'LOCALIZATION.COMMON.DEPLOYMENT_PENDING' | translate}}"
    [matTooltipDisabled]="!(('Lease Settings' | checkpendingJobs:'LeaseSettings') | async)">{{
    'LOCALIZATION.MENU_ITEMS.LEASE_SETTINGS' |
    translate}}
    <span class="deployment-pending-dot" *ngIf="(('Lease Settings' | checkpendingJobs:'LeaseSettings') | async)"></span>
  </a>
  <a routerLink="/pe-admin/payment-grid" [class.selected]="pathSelected === 'payment-grid'"
    (click)="onClick('payment-grid');" matTooltip="{{'LOCALIZATION.COMMON.DEPLOYMENT_PENDING' | translate}}"
    [matTooltipDisabled]="!(('Payment Grid Details' | checkpendingJobs:'PaymentGridDetails') | async)">
    {{ 'LOCALIZATION.MENU_ITEMS.PAYMENT_GRID' | translate}}
    <span class="deployment-pending-dot"
      *ngIf="(('Payment Grid Details' | checkpendingJobs:'PaymentGridDetails') | async)"></span>
  </a>
  <a [routerLink]="'/pe-admin/dealer-defined-campaigns'" [class.selected]="pathSelected === 'dealer-defined-campaigns'"
    (click)="onClick('dealer-defined-campaigns');">
    {{ 'LOCALIZATION.MENU_ITEMS.3RD_PARTY_CAMPAIGNS' | translate}}
  </a>
  <a *ngIf="dealerInfoService.isPPFeatureEnabled" [routerLink]="'/pe-admin/protection-products'"
    [class.selected]="pathSelected === 'protection-products'" (click)="onClick('protection-products');"
    matTooltip="{{'LOCALIZATION.COMMON.DEPLOYMENT_PENDING' | translate}}"
    [matTooltipDisabled]="!(('Protection Products' | checkpendingJobs:'ProtectionProducts') | async)">
    {{ 'LOCALIZATION.MENU_ITEMS.PROTECTION_PRODUCTS' | translate}}
    <span class="deployment-pending-dot"
      *ngIf="(('Protection Products' | checkpendingJobs:'ProtectionProducts') | async)"></span>
  </a>
  <a routerLink="/pe-admin/gross-profit" [class.selected]="pathSelected === 'gross-profit'"
    (click)="onClick('gross-profit');" matTooltip="{{'LOCALIZATION.COMMON.DEPLOYMENT_PENDING' | translate}}"
    [matTooltipDisabled]="!(('Gross Profit Configuration' | checkpendingJobs:'GrossProfitConfiguration') | async)">
    {{ 'LOCALIZATION.MENU_ITEMS.GROSS-PROFIT' | translate}}
    <span class="deployment-pending-dot"
      *ngIf="(('Gross Profit Configuration' | checkpendingJobs:'GrossProfitConfiguration') | async)"></span>
  </a>
  <a *ngIf="canActivateOnlyOnDev()" routerLink="/pe-admin/deal-validation"
    [class.selected]="pathSelected === 'deal-validation'" (click)="onClick('deal-validation');">
    {{ 'LOCALIZATION.MENU_ITEMS.DEAL_VALIDATION' | translate}}
  </a>
  <a *ngIf="canActivateOnlyOnQAandDev()" [routerLink]="'/pe-admin/incentives'"
    [class.selected]="pathSelected === 'incentives'" (click)="onClick('incentives');">
    {{ 'LOCALIZATION.MENU_ITEMS.INCENTIVES' | translate}}
  </a>
  <a [routerLink]="'/pe-admin/vehicle-pac'" [class.selected]="pathSelected === 'vehicle-pac'"
    (click)="onClick('vehicle-pac');">
    {{ 'LOCALIZATION.MENU_ITEMS.VEHICLE_PAC' | translate}}
  </a>
  <a routerLink="/pe-admin/deploy-job-management" [class.selected]="pathSelected === 'deploy-job-management'"
    (click)="onClick('deploy-job-management');">
    Deployment
  </a>
  <a routerLink="/pe-admin/audit" [class.selected]="pathSelected === 'audit'" (click)="onClick('audit');">
    {{ 'LOCALIZATION.MENU_ITEMS.AUDIT' | translate}}
  </a>
</nav>
<div *ngIf="!hideConfigStatus" class="dc-version-toggle">
  <input type="button" [ngClass]="{'primary-button': (draftState$ | async), 'secondary-button': !(draftState$ | async)}"
    style="pointer-events: none;"
    value="{{'LOCALIZATION.COMMON.' + ((draftState$ | async) ? 'DRAFT': 'ACTIVE') | translate}}" />
  <button mat-button [matMenuTriggerFor]="pedcmenu" class="p-1" style="min-width: inherit;">
    <mat-icon>keyboard_arrow_down</mat-icon>
    <mat-menu #pedcmenu="matMenu" xPosition="before">
      <button *ngIf="!(draftState$ | async)" mat-menu-item (click)="setDraftStatus(true)">
        {{'LOCALIZATION.COMMON.DRAFT' | translate}}</button>
      <button *ngIf="draftState$ | async" mat-menu-item (click)="setDraftStatus(false)">
        {{'LOCALIZATION.COMMON.ACTIVE' | translate}}</button>
    </mat-menu>
  </button>
</div>