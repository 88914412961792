import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as _ from "lodash";
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { GenericSelections } from '../../../shared/enums/generic-selections.enum';
import { OfferTypes } from '../../../shared/enums/offer-types.enum';
import { GenericActions } from '../../../shared/enums/operation-actions.enum';
import { ILenderInfo } from '../../../shared/models/dealer-configuration.model';
import { IAdvancePercentage, IDealerDefinedCampaign, IRate, IRateRule, IResidual, ITermRange, ITermRate, ITermResidual, IVariance } from '../../../shared/models/dealer-defined-campaigns.model';
import { IVehicleBrand } from '../../../shared/models/vehicle-config-master.model';
import { SubscriptionList, unsubscribeSubscriptions } from "../../../shared/services/util.service";
import { DealerConfigurationState } from '../../../store/dealer-configuration/dealer-configuration.reducer';
import { selectLenderList, selectMarkupRange, selectMaxStateApr, selectCampaignOffer } from '../../../store/dealer-configuration/dealer-configuration.selectors';
import * as dealerDefinedCampaignsActions from "../../../store/dealer-defined-campaigns/dealer-defined-campaigns.actions";
import { getCampaignUpdateComplete$ } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.adapter';
import { DealerDefinedCampaignsState } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.reducer';
import { selectDdSalesClasses, selectDefaultMileage, selectDefaultVariances } from '../../../store/dealer-defined-campaigns/dealer-defined-campaigns.selectors';
import { VehicleConfigState } from '../../../store/vehicle-config/vehicle-config.reducer';
import { selectVehicleConfig } from '../../../store/vehicle-config/vehicle-config.selectors';
import { DealerDefinedCampaignsComponentService } from '../dealer-defined-campaigns.component.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { IRangeByOffer } from "../models/range-by-offer.model";
import { FilterModelCodesPipe } from '../pipes/filter-model-codes.pipe';
import { ICampaignOffer } from '../../../shared/models/system-configuration.model';
@Component({
  selector: 'app-pe-modify-rate-rule',
  templateUrl: './modify-rate-rule.component.html',
  styleUrls: ['./modify-rate-rule.component.scss']
})
export class ModifyRateRuleComponent implements OnInit {

  private subs: SubscriptionList = {};
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  salesClasses$: Observable<string[]>;
  lenderList$: Observable<ILenderInfo[]>;
  vehicleConfig$: Observable<IVehicleBrand[]>;
  defaultVariances$: Observable<IVariance[]>;
  defaultMileage$: Observable<number>;
  defaultMileage: number;
  rateRuleForm: FormGroup;
  residualsForm: FormArray;
  termList: ITermRange[] = [];
  allOptionValue = GenericSelections.ALL;
  subCount: number = 0;
  offerTypes = OfferTypes;
  markupRange$: Observable<IRangeByOffer[]>;
  markupRange: IRangeByOffer[];
  campaignOffer$: Observable<ICampaignOffer>;
  campaignOffer: ICampaignOffer;

  @ViewChild('matDialogCloseBtn') matDialogCloseBtn: ElementRef<HTMLElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: {
      action: GenericActions, campaign: IDealerDefinedCampaign,
      rateRule: IRateRule, ruleIndex: number
    },
    private readonly dealerDefinedCampaignsState: Store<DealerDefinedCampaignsState>,
    private readonly dealerConfigurationState: Store<DealerConfigurationState>,
    private readonly vehicleConfigState: Store<VehicleConfigState>,
    readonly dDCCService: DealerDefinedCampaignsComponentService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly formBuilder: FormBuilder,
    private readonly filterModelCodesPipe: FilterModelCodesPipe
  ) { }

  ngOnInit(): void {
    this.lenderList$ = this.dealerConfigurationState.select(selectLenderList);
    this.salesClasses$ = this.dealerDefinedCampaignsState.select(selectDdSalesClasses);
    this.defaultVariances$ = this.dealerDefinedCampaignsState.select(selectDefaultVariances);
    this.defaultMileage$ = this.dealerDefinedCampaignsState.select(selectDefaultMileage);
    this.vehicleConfig$ = this.vehicleConfigState.select(selectVehicleConfig);
    this.dealerConfigurationState.select(selectDefaultMileage).pipe(filter(t => !!t), take(1)).subscribe(mileage => {
      this.defaultMileage = mileage;
    });
    this.dealerConfigurationState.select(selectMaxStateApr).pipe(filter(t => !!t), take(1)).subscribe(maxStateApr => {
      this.dDCCService.maxStateApr = maxStateApr;
    });
    this.campaignOffer$ = this.dealerConfigurationState.select(selectCampaignOffer);
    this.dealerConfigurationState.select(selectCampaignOffer).pipe(filter(t => !!t), take(1)).subscribe(campaignOffer => {
      this.campaignOffer = campaignOffer;
    });
    this.markupRange$ = this.dealerConfigurationState.select(selectMarkupRange);
    this.subs.selectMarkupRangeSub = this.dealerConfigurationState.select(selectMarkupRange).subscribe(data => {
      this.markupRange = data;
    });
    const campaign = this.data.campaign;
    const rateRule = this.data.rateRule;
    this.dDCCService.checkAndfetchVehicleConfig(campaign.make, this.vehicleConfigState);
    this.buildRateRuleForm();
    this.residualsForm = this.formBuilder.array([]);
    this.setupResidualsForm(rateRule.offerType, rateRule.salesClass, rateRule.modelCode, campaign.year, campaign.residuals);
    this.setupFormChangeEvents();
    this.setupCloseModalSubscription();
  }

  buildRateRuleForm() {
    const rateRule = this.data.rateRule;
    this.termList = rateRule.termRate.map(t => t.term);
    this.rateRuleForm = this.formBuilder.group({
      offerType: [rateRule.offerType, [Validators.required]],
      salesClass: [rateRule.salesClass, [Validators.required]],
      modelCode: [rateRule.modelCode, [Validators.required]],
      termRate: this.formBuilder.array([], [Validators.minLength(1)])
    }, { validators: [] });
    rateRule.termRate.forEach(termRate => {
      this.termRateForm.push(this.buildtermRateForm(termRate));
    });
  }

  buildtermRateForm(termRate: ITermRate) {
    const termRateForm = this.formBuilder.group({
      term: this.formBuilder.group({
        min: [termRate.term.min, [Validators.required]],
        max: [termRate.term.max, [Validators.required]]
      }),
      markup: [termRate.markup, [
        Validators.required, Validators.min(this.markupRange[0]?.min || 0),
        this.maxMarkupValidator(), this.markupAdjustmentWarningValidator()
      ]],
      advancePercentage: this.formBuilder.array([]),
      showWarning: [{ value: false, disabled: true }]
    });
    const advPercentageFormArray = termRateForm.get(['advancePercentage']) as FormArray;
    termRate.advancePercentage.forEach((advPercentage) => {
      advPercentageFormArray.push(this.buildAdvancePercentageForm(advPercentageFormArray, advPercentage));
    });
    termRateForm.setValidators(this.validateTerm());
    this.subs['minAdvSub' + this.subCount++] = termRateForm.get('term').get('min').valueChanges.subscribe(() => {
      this.triggerTermMinMaxControlsValidation();
    });
    this.subs['minAdvSub' + this.subCount++] = termRateForm.get('term').get('max').valueChanges.subscribe(() => {
      this.triggerTermMinMaxControlsValidation();
    });
    return termRateForm;
  }

  triggerTermMinMaxControlsValidation() {
    setTimeout(() => {
      this.termRateForm.controls.forEach(termRateValue => {
        termRateValue.get('term').get('min').updateValueAndValidity({ emitEvent: false });
        termRateValue.get('term').get('max').updateValueAndValidity({ emitEvent: false });
      });
    }, 0);
  }

  getMarkupConfig() {
    const offerTypeForm = this.rateRuleForm.get(['offerType']) as FormControl;
    return this.campaignOffer[offerTypeForm.value].markup.standard[0];
  }

  validateTerm(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const min = group.get(['term', 'min']).value;
      const max = group.get(['term', 'max']).value;
      const termRate = this.termRateForm.controls.filter((x) => !_.isEqual(x, group));
      const filterTermRate = termRate.filter(
        (x) =>
        ((x.value.term.min >= min && x.value.term.min <= max) ||
          (x.value.term.max >= min && x.value.term.max <= max) ||
          (x.value.term.min <= min && x.value.term.max >= max))
      );
      if (min > max) {
        group.get(['term', 'min']).setErrors({ notEquivalent: true });
        group.get(['term', 'max']).setErrors({ notEquivalent: true });
      } else if (filterTermRate?.length) {
        group.get(['term', 'min']).setErrors({ invalidTerm: true });
        group.get(['term', 'max']).setErrors({ invalidTerm: true });
      } else {
        if (min < this.getMarkupConfig().minTerm || min > this.getMarkupConfig().maxTerm) {
          group.get(['term', 'min']).setErrors({ invalidRange: true });
        }
        if (max < this.getMarkupConfig().minTerm || max > this.getMarkupConfig().maxTerm) {
          group.get(['term', 'max']).setErrors({ invalidRange: true });
        }
      }
      return;
    };
  }

  getTooltip(control: AbstractControl): string {
    if (control.hasError('notEquivalent')) {
      return this.translateService.instant('LOCALIZATION.CAMPAIGNS.MIN_GREATER_MAX');
    } else if (control.hasError('invalidRange')) {
      return this.translateService.instant('LOCALIZATION.COMMON.RANGE_VALIDATION_ERROR', { min: this.getMarkupConfig().minTerm, max: this.getMarkupConfig().maxTerm });
    } else if (control.hasError('invalidTerm')) {
      return this.translateService.instant('LOCALIZATION.CAMPAIGNS.MIN_MAX_OVERLAPPING_ERROR');
    }
    return '';
  }

  buildAdvancePercentageForm(advPercentageFormArray: FormArray, advPercentage: IAdvancePercentage) {
    const advPercentageValidators =
      this.dDCCService.isSETDealer ?
        [Validators.required,
        Validators.min(this.dDCCService.advPercentageRange.min),
        Validators.max(this.dDCCService.advPercentageRange.max)] : [];
    const advancePercentageForm = this.formBuilder.group({
      min: [advPercentage.min, advPercentageValidators],
      max: [advPercentage.max, advPercentageValidators],
      isDefault: [{ value: advPercentage.isDefault, disabled: advPercentage.isDefault }, [Validators.required]],
      rates: this.formBuilder.array([], [this.atleastNRequired(1)])
    }, {
      validators: this.dDCCService.isSETDealer ? [
        this.advPercentageMinMaxValidator(),
        this.advPercentageOverlapValidator(advPercentageFormArray)
      ] : []
    });
    if (this.dDCCService.isSETDealer) {
      this.subs['minAdvSub' + this.subCount++] = advancePercentageForm.get(['min']).valueChanges.subscribe(() => {
        setTimeout(() => {
          this.triggerAdvPercentageControlsValidation();
        }, 0);
      });
      this.subs['maxAdvSub' + this.subCount++] = advancePercentageForm.get(['max']).valueChanges.subscribe(() => {
        setTimeout(() => {
          this.triggerAdvPercentageControlsValidation();
        }, 0);
      });
      this.subs['isDefaultAdvSub' + this.subCount++] = advancePercentageForm.get(['isDefault']).valueChanges.subscribe((isDefault: boolean) => {
        if (isDefault) {
          setTimeout(() => {
            this.disableOtherDefaultsToggle(advancePercentageForm);
          }, 0);
        }
      });
    }
    const rateForm = advancePercentageForm.get(['rates']) as FormArray;
    const rates = this.dDCCService.creditTiers.map(creditTier => ({
      creditTier: creditTier.score,
      baseRate: _.find(advPercentage.rates || [], { creditTier: creditTier.score })?.baseRate || null
    } as IRate)
    );
    rates.forEach((rate) => {
      rateForm.push(this.buildRateForm(rate));
    });
    return advancePercentageForm;
  }

  buildRateForm(rate: IRate) {
    return this.formBuilder.group({
      creditTier: [rate.creditTier, [Validators.required]],
      baseRate: [rate.baseRate || null, [Validators.min(0),
      this.maxStateAprValidator(), this.maxLeaseRateValidator(),
      this.markupAdjustmentWarningOnBaseRateValidator()]]
    });
  }

  buildResidualsFormArray(residuals: IResidual[]) {
    this.residualsForm.clear();
    residuals.forEach(residual =>
      this.residualsForm.push(this.buildResidualForm(residual)));
  }

  buildResidualForm(residual: IResidual) {
    const residualForm = this.formBuilder.group({
      modelCode: [residual.modelCode],
      modelYear: [residual.modelYear],
      salesClass: [residual.salesClass],
      terms: this.formBuilder.array([]),
      variances: this.formBuilder.array([])
    });
    const termsResidualForm = residualForm.get(['terms']) as FormArray;
    const residualVariancesForm = residualForm.get(['variances']) as FormArray;
    residual.terms.forEach(term => {
      termsResidualForm.push(this.buildTermResidualForm(term));
    });
    residual.variances.forEach(variance => {
      residualVariancesForm.push(this.buildResidualVarianceForm(variance));
    });
    return residualForm;
  }

  buildTermResidualForm(residualTerm: ITermResidual) {
    return this.formBuilder.group({
      term: [residualTerm.term],
      value: [residualTerm.value, [Validators.required,
      Validators.min(this.dDCCService.residualRange.min),
      Validators.max(this.dDCCService.residualRange.max)]]
    });
  }

  buildResidualVarianceForm(variance: IVariance) {
    const varianceValidators = variance.mileage >= this.defaultMileage ?
      [Validators.required, Validators.min(this.dDCCService.hmrVariance.min), Validators.max(this.dDCCService.hmrVariance.max)] :
      [Validators.required, Validators.min(this.dDCCService.lmbVariance.min), Validators.max(this.dDCCService.lmbVariance.max)];
    return this.formBuilder.group({
      mileage: [variance.mileage],
      value: [variance.value, varianceValidators]
    });
  }

  setupFormChangeEvents() {
    const offerTypeForm = this.rateRuleForm.get(['offerType']) as FormControl;
    const salesClassForm = this.rateRuleForm.get(['salesClass']) as FormControl;
    const modelCodesForm = this.rateRuleForm.get(['modelCode']) as FormControl;
    this.subs.offerTypeValueChangesSub = offerTypeForm.valueChanges.subscribe((offerType: string) => {
      this.triggerTermRateControlsValidation();
      this.triggerBaseRateControlsValidation();
      this.setupResidualsForm(offerTypeForm.value, salesClassForm.value, modelCodesForm.value, this.data.campaign.year, this.residualsForm.value);
    });
    this.subs.salesClassValueChangesSub = salesClassForm.valueChanges.subscribe((salesClasses: string[]) => {
      this.triggerBaseRateControlsValidation();
      this.setupResidualsForm(offerTypeForm.value, salesClasses, modelCodesForm.value, this.data.campaign.year, this.residualsForm.value);
    });
    this.subs.modelCodeValueChangesSub = modelCodesForm.valueChanges.subscribe((modelCodes: string[]) => {
      this.setupResidualsForm(offerTypeForm.value, salesClassForm.value, modelCodes, this.data.campaign.year, this.residualsForm.value);
    });
  }

  setupResidualsForm(offerType: string, salesClasses: string[], modelCodes: string[], years: string[], existingResiduals: IResidual[]) {
    if (offerType === OfferTypes.FINANCE) {
      this.residualsForm.clear();
    }
    else if (offerType === OfferTypes.LEASE) {
      const residuals: IResidual[] = [];
      modelCodes.forEach(modelCode => {
        years.forEach(modelYear => {
          salesClasses.forEach(salesClass => {
            const existingResidual = _.find(existingResiduals, { modelCode, modelYear, salesClass });
            residuals.push(existingResidual ||
              this.dDCCService.buildDraftResidual(modelCode, modelYear, salesClass, this.termList));
          });
        });
      });
      this.buildResidualsFormArray(residuals);
    }
  }

  get termRateForm() {
    return this.rateRuleForm.get(['termRate']) as FormArray;
  }

  get offerTypeValue(): string {
    return this.rateRuleForm.get(['offerType']).value;
  }

  get salesClassValue(): string[] {
    return this.rateRuleForm.get(['salesClass']).value;
  }

  /* istanbul ignore next */
  addTerm(): void {
    const term: ITermRange = {
      min: null,
      max: null
    }
    this.termList.push(term);
    const termRate = this.dDCCService.buildDraftTermRate();
    this.termRateForm.push(this.buildtermRateForm(termRate));
    this.residualsForm.controls.forEach((residualFormItem: FormGroup) => {
      const termsForm = residualFormItem.get(['terms']) as FormArray;
      termsForm.push(this.buildTermResidualForm(this.dDCCService.buildDraftTermResidual(term)));
    });
  }

  removeTerm(termIndex: number): void {
    this.termList.splice(termIndex, 1);
    this.termRateForm.removeAt(termIndex);
    this.residualsForm.controls.forEach((residualFormItem: FormGroup) => {
      const termsForm = residualFormItem.get(['terms']) as FormArray;
      termsForm.removeAt(termIndex);
    });
    this.triggerTermMinMaxControlsValidation();
  }

  addAdvPencentage(advancePercentageForm: FormArray) {
    const advPercentageForm =
      this.buildAdvancePercentageForm(
        advancePercentageForm, { ...this.dDCCService.buildDraftAdvancePercentage(), isDefault: false }
      );
    advancePercentageForm.push(advPercentageForm);
  }

  deleteAdvPencentage(advancePercentageForm: FormGroup, advPercentageindex: number, advancePercentageFormArray: FormArray) {
    advancePercentageFormArray.removeAt(advPercentageindex);
    if (advancePercentageForm.get(['isDefault']).value) {
      const isDefault = advancePercentageFormArray.controls[0].get(['isDefault']);
      isDefault.patchValue(true, { emitEvent: false, onlySelf: true });
      isDefault.disable({ emitEvent: false, onlySelf: true });
    }
    this.triggerAdvPercentageControlsValidation();
  }

  setupCloseModalSubscription() {
    this.subs.getCampaignUpdateComplete = getCampaignUpdateComplete$().subscribe(() => {
      this.matDialogCloseBtn.nativeElement.click();
    });
  }

  /* istanbul ignore next */
  saveRateRule() {
    if (this.termList.length > 0 && this.rateRuleForm.valid && this.residualsForm.valid) {
      const campaign: IDealerDefinedCampaign = _.cloneDeep(this.data.campaign);
      const offerTypeName = _.startCase(this.rateRuleForm.get(['offerType']).value);

      const modelCodeNames: string[] = [];
      const modelList = this.filterModelCodesPipe.transform(campaign.make, campaign.model, campaign.year, this.dDCCService.vehicleConfig);
      this.rateRuleForm.get(['modelCode']).value.forEach(modelCode => {
        modelCodeNames.push(modelCode === this.allOptionValue
          ? _.startCase(this.allOptionValue)
          : this.dDCCService.getModelByCode(modelList, modelCode).name);
      });
      const salesClassNames: string[] = [];
      this.rateRuleForm.get(['salesClass']).value.forEach(salesClass => salesClassNames.push(_.startCase(salesClass)));
      const termRate = this.getFilteredTermRates();
      const rateRule: IRateRule = {
        ...this.rateRuleForm.getRawValue(),
        termRate,
        modelCodeNames,
        offerTypeName,
        salesClassNames
      };
      this.trimOutWarningFields(rateRule);
      const residuals: IResidual[] = this.residualsForm.getRawValue();
      const isNewRule = this.data.action === GenericActions.ADD;
      const rulesToCompare = isNewRule ? campaign.rateRules :
        _.filter(campaign.rateRules, (t, i) => i !== this.data.ruleIndex);
      if (this.dDCCService.findConflictingRateRules(rateRule, rulesToCompare)) {
        this.snackBar.open(
          this.translateService.instant('LOCALIZATION.CAMPAIGNS.DUPLICATE_RULE_ALERT'),
          this.translateService.instant('LOCALIZATION.COMMON.DISMISS'), { duration: 4000 }
        );
        return;
      }
      if (isNewRule) {
        campaign.rateRules.push(rateRule);
      }
      else {
        campaign.rateRules.splice(this.data.ruleIndex, 1, rateRule);
        if (rateRule.offerType === OfferTypes.FINANCE) {
          campaign.residuals = campaign.residuals.filter(t =>
            !(_.indexOf(rateRule.modelCode, t.modelCode) >= 0 &&
              _.indexOf(rateRule.salesClass, t.salesClass) >= 0 &&
              _.indexOf(campaign.year, t.modelYear) >= 0)
          );
        }
      }
      campaign.residuals = [...campaign.residuals, ...residuals];
      this.dealerDefinedCampaignsState.dispatch(new dealerDefinedCampaignsActions.UpdateDealerDefinedCampaign({ campaign }));
    }
  }

  getFilteredTermRates() {
    const termRates: ITermRate[] = this.rateRuleForm.getRawValue().termRate;
    termRates.forEach(t => {
      t.advancePercentage.forEach(t => {
        t.rates = t.rates.filter(t => !_.isNull(t.baseRate));
      });
    });
    return termRates;
  }

  trimOutWarningFields(rateRule: IRateRule) {
    rateRule.termRate.forEach(term => {
      delete term.showWarning;
    });
  }

  advPercentageMinMaxValidator(): ValidatorFn {
    return (_group: FormGroup) => {
      const min: number = _group.get(['min']).value;
      const max: number = _group.get(['max']).value;
      return min > max
        ? { InvalidMinMax: true } : null;
    }
  }

  atleastNRequired(val: number): ValidatorFn {
    return (_array: FormArray) => {
      const rateList: IRate[] = _array.value;
      return rateList.filter(t => !_.isNull(t.baseRate)).length >= val ? null : { atleastNRequired: true };
    }
  }

  advPercentageOverlapValidator(advPercentageFormArray: FormArray): ValidatorFn {
    return (_group: FormGroup) => {
      const advPercentageArray: IAdvancePercentage[] =
        _.filter(advPercentageFormArray.controls, (group) => !_.isEqual(_group, group)).map(t => t.value);
      const min: number = _group.get(['min']).value;
      const max: number = _group.get(['max']).value;
      const hasOverlap = _.some(advPercentageArray, (advItem) => {
        return min <= advItem.max && advItem.min <= max;
      });
      return hasOverlap
        ? { OverlappingMinMax: true } : null;
    }
  }

  maxStateAprValidator(): ValidatorFn {
    return (_control: FormControl) => {
      const baseRate = _control.value;
      if (this.offerTypeValue !== OfferTypes.FINANCE) {
        return null;
      }
      const maxApr = this.dDCCService.getMaxStateApr(this.salesClassValue);
      return baseRate > maxApr ? { maxStateAprExceeded: true } : null;
    }
  }

  maxLeaseRateValidator(): ValidatorFn {
    return (_control: FormControl) => {
      const baseRate = _control.value;
      return this.offerTypeValue === OfferTypes.LEASE &&
        baseRate > this.getMarkupConfig().max ? { maxLeaseRateExceeded: true } : null;
    }
  }

  maxMarkupValidator() {
    return (_control: FormControl) => {
      const markup = _control.value;
      const maxMarkup = this.getMarkupConfig().max;
      return markup > maxMarkup ? { maxMarkupExceeded: true } : null;
    }
  }

  markupAdjustmentWarningValidator(): ValidatorFn {
    return (_control: FormControl) => {
      const termRateControl = _control.parent as FormGroup;
      setTimeout(() => {
        this.setMarkupAdjustmentWarning(termRateControl);
      }, 0);
      return null;
    }
  }

  markupAdjustmentWarningOnBaseRateValidator(): ValidatorFn {
    return (_control: FormControl) => {
      const termRateControl = _control.parent?.parent?.parent?.parent?.parent as FormGroup;
      setTimeout(() => {
        this.setMarkupAdjustmentWarning(termRateControl);
      }, 0);
      return null;
    }
  }

  setMarkupAdjustmentWarning(termRateControl: FormGroup) {
    if (this.offerTypeValue === this.offerTypes.FINANCE) {
      const termRate: ITermRate = termRateControl?.value;
      if (termRate) {
        const maxApr = this.dDCCService.getMaxStateApr(this.salesClassValue);
        const markup = termRate.markup;
        const advPercentageArray = termRate.advancePercentage;
        const markupAdjustmentWarning =
          _.some(advPercentageArray, t =>
            _.some(t.rates, r => markup + r.baseRate > maxApr));
        termRateControl.get(['showWarning']).patchValue(markupAdjustmentWarning, { emitEvent: false });
      }
    }
  }

  triggerTermRateControlsValidation() {
    this.termRateForm.controls.forEach(termRate => {
      termRate.get(['markup']).updateValueAndValidity({ emitEvent: false });
    });
  }

  triggerBaseRateControlsValidation() {
    this.termRateForm.controls.forEach(termRate => {
      const advPercentageFormArray = termRate.get(['advancePercentage']) as FormArray;
      advPercentageFormArray.controls.forEach(advPercentage => {
        const ratesFormArray = advPercentage.get(['rates']) as FormArray;
        ratesFormArray.controls.forEach(rate => {
          rate.get(['baseRate']).updateValueAndValidity({ emitEvent: false });
        })
      });
    });
  }

  triggerAdvPercentageControlsValidation() {
    this.termRateForm.controls.forEach(termRate => {
      const advPercentageFormArray = termRate.get(['advancePercentage']) as FormArray;
      advPercentageFormArray.controls.forEach(advPercentage => {
        advPercentage.get(['min']).updateValueAndValidity({ emitEvent: false });
        advPercentage.get(['max']).updateValueAndValidity({ emitEvent: false });
      });
    });
  }

  /* istanbul ignore next */
  disableOtherDefaultsToggle(advancePercentageForm: FormGroup) {
    const advancePercentageFormArray = advancePercentageForm.parent as FormArray;
    advancePercentageFormArray.controls.forEach(advPercentage => {
      const isDefault = advPercentage.get(['isDefault'])
      if (!_.isEqual(advPercentage, advancePercentageForm)) {
        isDefault.patchValue(false, { emitEvent: false, onlySelf: true });
        isDefault.enable({ emitEvent: false, onlySelf: true });
      }
      else {
        isDefault.disable({ emitEvent: false, onlySelf: true });
      }
    });
  }

  ngOnDestroy() {
    unsubscribeSubscriptions(this.subs);
  }

}
