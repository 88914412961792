import { UserProfileService } from '@toyota/dd365-platform-library';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import * as uuid from 'uuid';
import { IDeployment, IDeploymentList } from '../models/deploy-job-management.model';
import _ from "lodash";
import { IGetAuditLogsResponse } from '../models/audit-log.model';

@Injectable({
    providedIn: 'root'
})
export class DeployJobManageService {
    private readonly DEALER_CONFIGURATION_API = environment.dealer_configuration_api;
    private readonly DEALER_EVENTS_API = environment.dealer_events_api;
    private readonly SUBSCRIBER_CODE = environment.x_subscriber_code;
    private httpOptions = {
        headers: new HttpHeaders()
    };
    constructor(private http: HttpClient,
        private readonly userProfileService: UserProfileService) {
    }

    private setHeaderOptions() {
        this.httpOptions.headers = new HttpHeaders({});
        this.httpOptions.headers = this.httpOptions.headers
            .set('Authorization', `Bearer ${this.userProfileService.getProfile().token}`)
            .set('x-subscriber-code', this.SUBSCRIBER_CODE)
            .set('x-transaction-id', uuid.v4());
    }

    /**
     * To get deployed job list with dealerCode
     *
     * @returns response
     */
    getDeploymentJobManage(dealerCode: string) {
        this.setHeaderOptions();
        return this.http.get(`${this.DEALER_CONFIGURATION_API}dealer/${dealerCode}/report`, this.httpOptions)
            .pipe(
                map((data: IDeploymentList) => {
                    return data;
                })
            );
    }

    /**
     * To get deployment job config with dealerCode
     *
     * @returns response
     */
    getDeploymentJobConfig(dealerCode: string) {
        this.setHeaderOptions();
        return this.http.get(`${this.DEALER_EVENTS_API}dealer/${dealerCode}/auditLogs`, this.httpOptions)
            .pipe(
                map((data: IGetAuditLogsResponse) => {
                    return data;
                })
            );
    }

    /**
     * To get detail csv for deployment job with dealerCode and job id
     *
     * @returns response
     */
    getJobDeploymentDetailCSV(detailQueryParam: any) {
        this.setHeaderOptions();
        this.httpOptions.headers = this.httpOptions.headers
            .set('Accept', 'text/csv');
        return this.http.get(`${this.DEALER_CONFIGURATION_API}dealer/${detailQueryParam.dealer}/report?jobId=${detailQueryParam.jobId}`,
            { responseType: 'blob', headers: this.httpOptions.headers })
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }

    /**
     * To get deployment info by id
     *
     * @returns response
     */
    getDeploymentById(id: any, dealerCode: string) {
        this.setHeaderOptions();
        return this.http.get(`${this.DEALER_CONFIGURATION_API}dealer/${dealerCode}/deploy/${id}`, this.httpOptions)
            .pipe(
                map((data: IDeployment) => {
                    return data;
                })
            );
    }

    /**
     * POST call to deploy configurations
     *
     * @returns response
     */
    deployConfigurations(dealerCode: string) {
        this.setHeaderOptions();
        return this.http.post(`${this.DEALER_CONFIGURATION_API}dealer/${dealerCode}/deploy`, {}, this.httpOptions).pipe(
            map((data: any) => {
                return data;
            })
        );
    }

    /**
 * POST call to discard draft configuration changes
 *
 * @returns response
 */
    discardDraftConfiguration(dealerCode: string, discardBody: any) {
        this.setHeaderOptions();
        return this.http.post(`${this.DEALER_CONFIGURATION_API}dealer/${dealerCode}/discard`, discardBody, this.httpOptions).pipe(
            map((data: any) => {
                return data;
            })
        );
    }


    /**
    * GET call to get Audit Logs
    *
    * @returns response
    */
    getAuditLogs(dealerCode: string, deploymentid: string) {
        this.setHeaderOptions();
        return this.http.get(`${this.DEALER_EVENTS_API}dealer/${dealerCode}/auditLogs?deploymentId=${deploymentid}`, this.httpOptions).pipe(
            map((data: any) => {
                return data;
            })
        );
    }
}